<template>
  <title-banner titlebanner="FAQ" />
</template>

<script>
import titleBanner from "/src/components/title-banner.vue";

export default {
  name: "FAQ",
  components: { titleBanner },
  mounted() {},
};
</script>
