<template>
  <title-banner titlebanner="Contact Us" />
</template>

<script>
import titleBanner from "/src/components/title-banner.vue";

export default {
  name: "Contact",
  components: { titleBanner },
  mounted() {},
};
</script>
