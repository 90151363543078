<template>
	<div
		class="
			bg-white
			rounded-lg
			p-6
			m-0
			md:m-2
			w-full
			flex flex-col
			animate-fade-in-up
		"
	>
		<h2 class="text-xl font-bold">{{ cardtitle }}</h2>
		<p class="flex-grow pb-4">{{ cardbody }}</p>

		<router-link
			:to="cardlink"
			tag="button"
			class="rounded-md text-md px-6 py-2 text-white text-center bg-blue-500"
			>{{ cardbtn }}</router-link
		>
	</div>
</template>

<script>
export default {
	props: ["cardtitle", "cardbody", "cardlink", "cardbtn"],
};
</script>
