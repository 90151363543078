<template>
	<nav
		:class="scrollPosition > 50 ? 'h-10 bg-gray-500' : 'h-16 bg-blue-500'"
		class="
			flex
			items-center
			justify-between
			flex-wrap
			px-8
			sticky
			top-0
			transition-all
		"
	>
		<div class="flex items-center flex-shrink-0 text-white mr-6">
			<span
				:class="scrollPosition > 20 ? 'text-md' : 'text-xl'"
				class="font-semibold tracking-tight transition-all"
				>RITBA E-ZPass</span
			>
		</div>
		<div class="block md:hidden">
			<button
				class="
					flex
					items-center
					px-3
					py-2
					border
					rounded
					text-gray-200
					border-gray-200
					hover:text-white
					hover:border-white
				"
			>
				<svg
					class="fill-current h-3 w-3"
					viewBox="0 0 20 20"
					xmlns="http://www.w3.org/2000/svg"
				>
					<title>Menu</title>
					<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
				</svg>
			</button>
		</div>
		<div class="w-full block flex-grow md:flex md:items-center md:w-auto">
			<div class="hidden md:block text-sm flex-grow">
				<router-link
					to="/"
					class="
						uppercase
						block
						mt-4
						md:inline-block
						md:mt-0
						text-gray-200
						hover:text-white
						mr-4
					"
				>
					Home
				</router-link>
				<router-link
					to="/plans"
					class="
						uppercase
						block
						mt-4
						md:inline-block
						md:mt-0
						text-gray-200
						hover:text-white
						mr-4
					"
				>
					Plans
				</router-link>
				<router-link
					to="/faq"
					class="
						uppercase
						block
						mt-4
						md:inline-block
						md:mt-0
						text-gray-200
						hover:text-white
						mr-4
					"
				>
					FAQ
				</router-link>
				<router-link
					to="/contact"
					class="
						uppercase
						block
						mt-4
						md:inline-block
						md:mt-0
						text-gray-200
						hover:text-white
						mr-4
					"
				>
					Contact
				</router-link>
			</div>
			<div>
				<a
					class="
						inline-block
						text-sm
						px-4
						py-2
						leading-none
						border
						rounded
						text-white
						border-white
						hover:border-transparent
						hover:text-teal-500
						hover:bg-white
						mt-4
						md:mt-0
					"
					>Log In</a
				>
			</div>
		</div>
	</nav>
</template>

<script>
export default {
	name: "Navbar",
	data() {
		return {
			scrollPosition: null,
		};
	},
	methods: {
		updateScroll() {
			this.scrollPosition = window.scrollY;
		},
	},
	mounted() {
		window.addEventListener("scroll", this.updateScroll);
	},
};
</script>

<style>
a.router-link-exact-active {
	color: white;
}
</style>
