<template>
	<div class="p-16 bg-gray-700 text-center">
		<h1 class="text-white text-4xl font-bold mb-8 animate-fade-in-down">
			{{ titlebanner }}
		</h1>
		<p class="text-white text-lg">{{ actiontext }}</p>
		<div class="py-8">
			<router-link
				:to="location"
				tag="button"
				class="w-[300px] border border-white rounded-md text-xl px-8 py-4 text-white"
				>Do Something</router-link
			>
		</div>
	</div>
</template>

<script>
export default {
	props: ["titlebanner", "location", "actiontext"],
};
</script>
