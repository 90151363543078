<template>
  <div class="p-16 bg-teal-500">
    <h1 class="text-white text-center text-4xl font-bold">
      {{ titlebanner }}
    </h1>
  </div>
</template>

<script>
export default {
  props: ["titlebanner"],
};
</script>
