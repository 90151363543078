<template>
  <div class="px-16 py-8 bg-gray-200 fixed bottom-0 w-full">
    <p>Footer</p>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>
