<template>
	<cta-banner
		titlebanner="RI E-ZPass Home"
		location="/contact"
		actiontext="This is some call to action text"
	></cta-banner>

	<div class="flex px-4 md:px-14 py-8 flex-col md:flex-row">
		<card-primary
			cardtitle="Card One"
			cardbody="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellat beatae nihil ipsam quis porro maiores delectus eveniet."
			cardlink="/contact"
			cardbtn="Contact"
		></card-primary>
		<card-primary
			cardtitle="Card Two"
			cardbody="Sit amet consectetur adipisicing elit. Repellat beatae nihil ipsam quis porro maiores delectus eveniet."
			cardlink="/faq"
			cardbtn="FAQ"
		></card-primary>
		<card-primary
			cardtitle="Card Three"
			cardbody="Lorem ipsum dolor, sit amet consectetur adipisicing elit."
			cardlink="/plans"
			cardbtn="Plans"
		></card-primary>
	</div>

	<div class="p-8 md:p-16">
		<h2 class="font-bold text-xl">Headline</h2>
		<p>
			Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut nesciunt
			iusto perspiciatis, eligendi dolorum hic quam dolore quae exercitationem
			est fugit facere ipsam repudiandae harum a natus quaerat? Quae in ratione
			deserunt eaque, autem quo corrupti cupiditate hic eius perspiciatis
			veritatis ducimus quia, dolorum ullam, officiis eligendi! Nam, temporibus
			vel! Quod corrupti ut harum officia maiores enim sapiente perferendis et
			veniam sint praesentium beatae saepe nobis reiciendis, ipsa eos culpa
			consequatur optio distinctio repellat quae vel nesciunt fuga! Sunt laborum
			non exercitationem repellat quibusdam, sit voluptas laboriosam minus ea
			eligendi nesciunt necessitatibus incidunt accusamus ullam nam, quo quis
			atque id corrupti labore iure tempora! Atque reiciendis doloremque totam
			doloribus, ullam dolorem et officiis excepturi saepe aspernatur, odit
			minus tempore id architecto numquam quidem, quisquam expedita quod iusto
			minima! Provident eius nemo dolor explicabo, perferendis dicta odio fuga
			id possimus eveniet non repellat, placeat sapiente. Dicta quibusdam
			obcaecati nihil pariatur, velit saepe optio corrupti ipsa distinctio harum
			perspiciatis alias culpa magnam blanditiis totam porro non laboriosam
			error sint voluptatem? Culpa nostrum vero, nihil iusto facere eum ipsum
			architecto accusantium suscipit earum deleniti delectus aspernatur alias
			iure aut fugit. Quas, labore optio eos, quidem delectus nulla non
			repudiandae temporibus eligendi harum, est tenetur quos illo assumenda
			amet saepe? Dicta blanditiis incidunt placeat, delectus distinctio nisi
			laudantium facilis praesentium expedita reprehenderit ullam labore optio
			fuga.
		</p>
	</div>
</template>

<script>
import ctaBanner from "/src/components/cta-banner.vue";
import cardPrimary from "/src/components/card-primary.vue";

export default {
	name: "Home",
	components: { ctaBanner, cardPrimary },
	mounted() {},
};
</script>
