<template>
  <navbar></navbar>
  <router-view />
  <main-footer></main-footer>
</template>

<script>
import navbar from "/src/components/navbar.vue";
import mainFooter from "/src/components/main-footer.vue";

export default {
  name: "Index",
  components: { navbar, mainFooter },
  mounted() {},
};
</script>
